import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContentBlock from '../DataPresentation/ContentBlock';
import { Container, CircularProgress, Typography, Card, CardContent } from '@mui/material';
import { AppDispatch, RootState } from '../../store';
import { IContentBlock, fetchProductDetails, IProductDetails } from '../../store/slices/productSlice';
import ProductHeader from './ProductHeader';
import Title from '../DataPresentation/Title';
import Images from '../DataPresentation/Images';

interface ProductPageProps {
  productId: string;
}

const ProductPage: React.FC<ProductPageProps> = ({ productId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const contentRef = useRef<HTMLDivElement | null>(null);

  const productDetailState = useSelector(
    (state: RootState) => state.products.productDetails[productId]
  );

  const productDetails: IProductDetails | null | undefined = productDetailState?.details;
  const loading: boolean = productDetailState?.loading || false;
  const error: string | null = productDetailState?.error || null;

  useEffect(() => {
    if (!productDetails && !loading && !error) {
      dispatch(fetchProductDetails(productId));
    }
  }, [dispatch, productId, productDetails, loading, error]);

  const scrollToContent = () => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (loading) {
    return (
      <Container sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography variant="h6" color="error">
          Ошибка: {error}
        </Typography>
      </Container>
    );
  }

  if (!productDetails) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography variant="h6">Продукт не найден.</Typography>
      </Container>
    );
  }

  return (
    <>
      <ProductHeader productId={productId} scrollToContent={scrollToContent} />
      <Container sx={{ mt: 4 }} ref={contentRef}>
        <Title name={productDetails.name} />
        <Images images={productDetails.mainImages} />

        {productDetails.contentBlocks.map((block: IContentBlock, index: number) => (
          <Card key={index} sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
            <CardContent>
              <ContentBlock block={block} />
            </CardContent>
          </Card>
        ))}
      </Container>
    </>
  );
};

export default ProductPage;
