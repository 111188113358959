import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  Box,
  Typography,
  Divider,
  Toolbar,
  IconButton,
} from '@mui/material';
import { Close, ArrowBack } from '@mui/icons-material';
import { useAppSelector } from '../../store/hooks';
import HoverLink from '../HoverLink';
import { Category } from '../../store/slices/catalogSlice';
import { useNavigate } from 'react-router-dom';

interface NavBarMobileProps {
  drawerOpen: boolean;
  toggleDrawer: (open: boolean) => void;
}

const navLinks = [
  { label: 'Каталог', isCatalog: true },
  { to: '/company', label: 'Компания' },
  { to: '/contacts', label: 'Контакты' },
  { to: '/how-to-buy', label: 'Как купить' },
  { to: '/services', label: 'Услуги' },
  { to: '/videos', label: 'Видео' },
];

const NavBarMobile: React.FC<NavBarMobileProps> = ({ drawerOpen, toggleDrawer }) => {
  const [currentCategory, setCurrentCategory] = useState<Category | null>(null);
  const [isCatalogView, setIsCatalogView] = useState(false);
  const categories = useAppSelector((state) => state.catalog.categories);

  const navigate = useNavigate();

  const handleCategoryClick = (category: Category) => {
    if (category.type === 'final') {
      navigate(`/catalog/${category.id}`);
      toggleDrawer(false);
      setCurrentCategory(null);
    } else {
      setCurrentCategory(category);
    }
  };

  const handleBackClick = () => {
    if (currentCategory) {
      setCurrentCategory((prevCategory) => {
        if (!prevCategory) return null;
        return categories.find((cat) =>
          cat.children?.some((child) => child.id === prevCategory.id)
        ) || null;
      });
    } else {
      // Если мы в корневой категории, возвращаемся в основное меню
      setIsCatalogView(false);
    }
  };

  const handleCatalogClick = () => {
    setIsCatalogView(true); // Переключение в представление категорий
  };

  const handleCloseDrawer = () => {
    setCurrentCategory(null);
    setIsCatalogView(false); // Возвращаемся в представление "Меню"
    toggleDrawer(false);
  };

  const displayedCategories = currentCategory?.children || categories.filter((cat) => cat.type === 'root');

  const currentCategoryName = currentCategory
    ? categories.find((cat) => cat.id === currentCategory.id)?.name || 'Каталог'
    : 'Каталог';

  const currentHeader = isCatalogView ? currentCategoryName : 'Меню'; // Меняем заголовок в зависимости от представления

  return (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClose={handleCloseDrawer}
      sx={{ width: '100%', maxWidth: '300px' }}
    >
      <Box sx={{ width: 300, p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ flexShrink: 0 }}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            {isCatalogView && (
              <IconButton onClick={handleBackClick} sx={{ mr: 1 }}>
                <ArrowBack />
              </IconButton>
            )}
            <Typography variant="h6">{currentHeader}</Typography>
            <IconButton onClick={handleCloseDrawer}>
              <Close />
            </IconButton>
          </Toolbar>

          <Divider />
        </Box>

        <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
          {isCatalogView ? (
            <List>
              {displayedCategories.map((category) => (
                <ListItem key={category.id}>
                  <HoverLink
                    label={category.name}
                    onClick={() => handleCategoryClick(category)}
                    underlineColor="black"
                    variant="body1"
                    sx={{
                      width: '100%',
                      cursor: 'pointer',
                    }}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <List>
              {navLinks.map((link, index) => (
                <ListItem key={index}>
                  {link.isCatalog ? (
                    <Typography
                      variant="h6"
                      onClick={handleCatalogClick}
                      sx={{ cursor: 'pointer', textAlign: 'center', width: '100%' }}
                    >
                      {link.label}
                    </Typography>
                  ) : (
                    <Typography variant="h6" sx={{ textAlign: 'center', width: '100%' }}>
                      <HoverLink
                        to={link.to || '#'}
                        label={link.label}
                        underlineColor="black"
                        variant="h6"
                      />
                    </Typography>
                  )}
                </ListItem>
              ))}
            </List>
          )}
        </Box>

        {!isCatalogView && (
          <Box sx={{ flexShrink: 0, mt: 'auto', p: 2 }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
              Телефон в Шанхае:
            </Typography>
            <Box sx={{ mb: 2 }}>
              <HoverLink
                to="tel:+8615502106310"
                label="+86 155-021-063-10"
                underlineColor="black"
                color="inherit"
                variant="body1"
              />
            </Box>

            <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 2 }}>
              Телефоны в РФ:
            </Typography>
            <Box sx={{ mb: 1 }}>
              <HoverLink
                to="tel:+79108364995"
                label="+7 910 836-49-95"
                underlineColor="black"
                color="inherit"
                variant="body1"
              />
            </Box>
            <Box>
              <HoverLink
                to="tel:+79109351152"
                label="+7 910 935-11-52"
                underlineColor="black"
                color="inherit"
                variant="body1"
              />
            </Box>

            <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 2 }}>
              Email:
            </Typography>
            <Box>
              <HoverLink
                to="mailto:yuzhen.torg@gmail.com"
                label="yuzhen.torg@gmail.com"
                underlineColor="black"
                color="inherit"
                variant="body1"
              />
            </Box>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default NavBarMobile;
