import React, { useRef, useState, useEffect } from 'react';
import { Box, Typography, Slide } from '@mui/material';
import CatalogMenu from './CatalogMenu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import HoverLink from '../HoverLink';
import { useNavbarStyle } from '../NavbarStyleContext';
import ServicesMenu from './ServicesMenu';

interface NavbarLinksProps {
  isSticky: boolean;
}

const NavbarLinks: React.FC<NavbarLinksProps> = ({ isSticky }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showLinks, setShowLinks] = useState(false);
  const catalogButtonRef = useRef<HTMLElement | null>(null);
  const closeTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isServicesMenuOpen, setIsServicesMenuOpen] = useState(false);
  const servicesButtonRef = useRef<HTMLElement | null>(null);
  const servicesCloseTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { navbarStyle } = useNavbarStyle();

  useEffect(() => {
    setShowLinks(true);
  }, []);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
      closeTimeoutRef.current = null;
    }
    setIsMenuOpen(true);
    catalogButtonRef.current = event.currentTarget;
  };

  const handleMenuClose = (delay = 300) => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
    closeTimeoutRef.current = setTimeout(() => {
      setIsMenuOpen(false);
    }, delay);
  };

  const handleImmediateClose = () => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
    setIsMenuOpen(false);
  };

  const handleServicesMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (servicesCloseTimeoutRef.current) {
      clearTimeout(servicesCloseTimeoutRef.current);
      servicesCloseTimeoutRef.current = null;
    }
    setIsServicesMenuOpen(true);
    servicesButtonRef.current = event.currentTarget;
  };

  const handleServicesMenuClose = (delay = 300) => {
    if (servicesCloseTimeoutRef.current) {
      clearTimeout(servicesCloseTimeoutRef.current);
    }
    servicesCloseTimeoutRef.current = setTimeout(() => {
      setIsServicesMenuOpen(false);
    }, delay);
  };

  const handleServicesClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsServicesMenuOpen(false);
  };

  const isLightStyle = navbarStyle === 'light';
  const textColor = isLightStyle ? 'black' : 'white';

  const navLinks = [
    { to: '/company', label: 'Компания' },
    { to: '/contacts', label: 'Контакты' },
    { to: '/how-to-buy', label: 'Как купить' },
    { to: '/services', label: 'Услуги' },
    { to: '/videos', label: 'Видео' },
  ];

  return (
    <Box
      sx={{
        display: { xs: 'none', lg: 'flex' },
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '20px',
      }}
    >
      <Slide in={showLinks} direction="down" timeout={{ enter: 500 }} style={{ transitionDelay: '100ms' }}>
        <Box
          sx={{
            display: { xs: 'none', lg: 'flex' },
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 999,
            width: 150,
            height: 80,
            position: 'relative',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onMouseEnter={(event) => {
            handleMenuOpen(event);
          }}
          onMouseLeave={() => {
            handleMenuClose(100);
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: '24px',
                height: '24px',
                mr: 1,
              }}
            >
              <MenuIcon
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  opacity: isMenuOpen ? 0 : 1,
                  transform: isMenuOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                  transition: 'opacity 0.3s, transform 0.3s',
                  color: isSticky ? 'black' : 'inherit',
                }}
              />
              <MenuOpenIcon
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  opacity: isMenuOpen ? 1 : 0,
                  transform: isMenuOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
                  transition: 'opacity 0.3s, transform 0.3s',
                  color: isSticky ? 'black' : 'inherit',
                }}
              />
            </Box>
            <Typography variant="h6">
              <HoverLink
                to="/catalog"
                label="Каталог"
                color={isSticky ? 'black' : 'inherit'}
                underlineColor={isSticky ? 'black' : textColor}
                variant="h6"
              />
            </Typography>
          </Box>
        </Box>
      </Slide>

      <CatalogMenu
        anchorEl={catalogButtonRef.current}
        isOpen={isMenuOpen}
        onClose={() => {
          if (closeTimeoutRef.current) {
            clearTimeout(closeTimeoutRef.current);
            closeTimeoutRef.current = null;
          }
          setIsMenuOpen(false);
        }}
        onMouseEnter={() => {
          if (closeTimeoutRef.current) {
            clearTimeout(closeTimeoutRef.current);
            closeTimeoutRef.current = null;
          }
        }}
        onMouseLeave={() => handleMenuClose(1000)}
      />
      <Box
        sx={{
          display: { xs: 'none', lg: 'flex' },
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '20px',
          width: '100%',
        }}
      >
        {navLinks.map((link, index) => (
          <Slide
            key={link.to}
            in={showLinks}
            direction="down"
            timeout={{ enter: 500 }}
            style={{ transitionDelay: `${150 + index * 100}ms` }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 'auto',
                height: 64,
                position: 'relative',
                zIndex: 1,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onMouseEnter={link.to === '/services' ? handleServicesMenuOpen : undefined}
              onMouseLeave={link.to === '/services' ? () => handleServicesMenuClose(1000) : undefined}
            >
              <Typography>
                <HoverLink
                  to={link.to}
                  label={link.label}
                  color={isSticky ? 'black' : 'inherit'}
                  underlineColor={isSticky ? 'black' : textColor}
                  variant="h6"
                  onClick={link.to === '/services' ? handleServicesClick : undefined}
                />
              </Typography>
            </Box>
          </Slide>
        ))}
      </Box>
      <ServicesMenu
        anchorEl={servicesButtonRef.current}
        isOpen={isServicesMenuOpen}
        onClose={() => {
          if (servicesCloseTimeoutRef.current) {
            clearTimeout(servicesCloseTimeoutRef.current);
            servicesCloseTimeoutRef.current = null;
          }
          setIsServicesMenuOpen(false);
        }}
        onMouseEnter={() => {
          if (servicesCloseTimeoutRef.current) {
            clearTimeout(servicesCloseTimeoutRef.current);
            servicesCloseTimeoutRef.current = null;
          }
        }}
        onMouseLeave={() => handleServicesMenuClose(300)}
        isSticky={isSticky}
      />
    </Box>
  );
};

export default NavbarLinks;
