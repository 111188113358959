// src/components/HowToBuy/HowToBuy.tsx

import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import PaymentSection from './PaymentSection';
import DeliverySection from './DeliverySection';
import WarrantySection from './WarrantySection';
import { Fade, Zoom } from '@mui/material';

const HowToBuy: React.FC = () => {
  return (
    <Container maxWidth="md" sx={{ paddingY: 5 }}>
      <Typography variant="h3" align="center" gutterBottom>
        Как купить
      </Typography>

      <Fade in={true} timeout={1000}>
        <Box>
          <PaymentSection />
        </Box>
      </Fade>

      <Zoom in={true} timeout={1000}>
        <Box>
          <DeliverySection />
        </Box>
      </Zoom>

      <Fade in={true} timeout={1000}>
        <Box>
          <WarrantySection />
        </Box>
      </Fade>
    </Container>
  );
};

export default HowToBuy;
