// catalogSlice.ts

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_BASE_URL } from '../../config/api';

export interface Category {
  id: string;
  name: string;
  type: 'root' | 'subcategory' | 'final';
  image?: string;
  rows?: number;
  cols?: number;
  description?: string;
  children?: Category[];
}

interface CatalogState {
  categories: Category[];
  loading: boolean;
  error: string | null;
}

const initialState: CatalogState = {
  categories: [],
  loading: false,
  error: null,
};

export const fetchCategories = createAsyncThunk(
  'catalog/fetchCategories',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`/api/categories`);
      return response.data as Category[];
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default catalogSlice.reducer;
