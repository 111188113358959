// src/components/ProductList.tsx

import React from 'react';
import { Box, Grid2 } from '@mui/material';
import ProductCardMini from '../Product/ProductCardMini';
import CarouselList from '../CarouselList';
import { IProduct } from '../../store/slices/productSlice';
import AnimatedCard from '../AnimatedCard';

interface ProductListProps {
  products: IProduct[];
  horizontal?: boolean;
}

const ProductList: React.FC<ProductListProps> = ({ products, horizontal = false }) => {
  if (horizontal) {
    return (
      <CarouselList slidesToShow={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4 }}>
        {products.map((product, idx) => (
          <Box key={product.id} sx={{ margin: 1, height: '100%' }}>
            <AnimatedCard
              initialDelay={100}
              stepDelay={50}
              animationDuration={1000}
              height="100%"
            >
              <ProductCardMini product={product} />
            </AnimatedCard>
          </Box>
        ))}
      </CarouselList>
    );
  } else {
    return (
      <Grid2 container spacing={2} columns={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 4 }}>
        {products.map((product, idx) => (
          <Grid2 key={product.id} size={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}>
            <AnimatedCard
              initialDelay={100}
              stepDelay={100}
              animationDuration={1000}
              height="100%"
            >
              <ProductCardMini product={product} />
            </AnimatedCard>
          </Grid2>
        ))}
      </Grid2>
    );
  }
};

export default ProductList;
