import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Container, IconButton, Box, Slide } from '@mui/material';
import { Menu as MenuIcon, Search as SearchIcon } from '@mui/icons-material';
import { useAppDispatch } from '../../store/hooks';
import { fetchCategories } from '../../store/slices/catalogSlice';
import NavbarLinks from './NavbarLinks';
import Logo from './Logo';
import ContactInfo from './ContactInfo';
import { useNavbarStyle } from '../NavbarStyleContext';
import NavBarMobile from './NavBarMobile';

const Navbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isSticky, setIsSticky] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const { navbarStyle } = useNavbarStyle();

  useEffect(() => {
    dispatch(fetchCategories());

    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    const navbarTimeout = setTimeout(() => {
      setShowNavbar(true);
    }, 500);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(navbarTimeout);
    };
  }, [dispatch]);

  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
  };

  const isLightStyle = navbarStyle === 'light';
  const textColor = isLightStyle ? 'black' : 'white';

  return (
    <>
      <Slide in={showNavbar} direction="down" timeout={{ enter: 500 }}>
        <AppBar
          position="sticky"
          sx={{
            top: 0,
            transition: 'box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.3s ease-in-out',
            boxShadow: isSticky ? 6 : 0,
            transform: isSticky ? 'translateY(0)' : 'translateY(-10px)',
            backgroundColor: 'rgba(255, 255, 255, 0)',
            color: isSticky ? 'black' : textColor,
            margin: 0,
            padding: 0,
            width: "100%",
            display: 'flex',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backdropFilter: isSticky ? 'blur(10px)' : 'none',
              backgroundColor: isSticky ? 'rgba(255, 255, 255, 0.8)' : 'rgba(255, 255, 255, 0)',
              transition: 'backdrop-filter 0.3s ease-in-out, background-color 0.3s ease-in-out',
              zIndex: -1,
            },
          }}
        >
          <Container maxWidth="xl" sx={{margin: 0, width: "100%", p: 0}}>
            <Toolbar
              sx={{
                justifyContent: 'space-between',  // Равномерное распределение компонентов по горизонтали
                alignItems: 'center',  // Все компоненты выровнены по центру по вертикали
                display: 'flex',  // Flexbox для выравнивания
                gap: '20px',  // Одинаковый промежуток между всеми компонентами
                position: 'relative',
                width: "98vw",
              }}
            >
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={() => toggleDrawer(true)}
                sx={{
                  display: { xs: 'block', lg: 'none' },
                  marginRight: 5,
                }}
              >
                <MenuIcon />
              </IconButton>

              <Logo isSticky={isSticky} textColor={textColor} />

              <NavbarLinks isSticky={isSticky} />

              <ContactInfo isSticky={isSticky} textColor={textColor} />
              <Box marginLeft={2}>
                <IconButton edge="end" color="inherit" aria-label="search" size="large">
                  <SearchIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Slide>

      <NavBarMobile drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
    </>
  );
};

export default Navbar;
