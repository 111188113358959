// src/components/BackToTopButton.tsx

import React, { useEffect, useState } from 'react';
import { Fab, Zoom, Box } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const BackToTopButton: React.FC = () => {
  const [visible, setVisible] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const viewportHeight = window.innerHeight;

    setVisible(scrollY > viewportHeight);
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Zoom in={visible}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          zIndex: 1000,
        }}
      >
        <Fab
          color="default"
          size="large"
          aria-label="Прокрутить вверх"
          sx={{
            backgroundColor: 'rgba(240,240,240,0.7)',
            backdropFilter: 'blur(8px)',
            color: 'black',
            '&:hover': {
              backgroundColor: 'rgba(180, 180, 180, 0.7)',
            },
          }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </Box>
    </Zoom>
  );
};

export default BackToTopButton;
